<template>
<div 
	class='richText' 
	:id='id'
	:class='{
		disabled,
		focussed: editorFocussed || (inEditor && !$store.state.main.mobile && $refs.editorMenuBubble && $refs.editorMenuBubble.menu.isActive),
		tooLong: errTooLong
	}'
> 
	<div v-if='errTooLong' class='errTooLong miniButton alert'>{{$t('elements.labels.tooLong')}}</div>
	<EditorMenuBubble 
		ref='editorMenuBubble'
		:editor='editor' 
		class='menuBubble'
		v-if='!plain && !$store.state.main.mobile'
	>
		<div 
			slot-scope='{ commands, isActive, getMarkAttrs, menu }' 
			v-position='{ left: menu.left, bottom: menu.bottom, container: $el }'
			:class='{ isActive: menu.isActive && inEditor }' 
		>
		<!--
			<form v-if='linkMenuIsActive' @submit.prevent='setLinkUrl(commands.link, linkUrl)'>
				<input 
					type='text' 
					v-model='linkUrl' 
					placeholder='http://' 
					ref='linkInput' 
					@keydown.esc='hideLinkMenu' class='textInput' 
					@keydown.tab='hideLinkMenu'
					@blur='hideLinkMenu'
				/>
				<button @mousedown='setLinkUrl(commands.link, linkUrl)'>
					<svg class='smallIcon'>
						<use xlink:href='#check' />
					</svg>	
				</button>
			</form>
		-->
			<button :class='{ isActive: isActive.bold() }' @click='commands.bold'>
				<svg class='miniIcon'>
					<use xlink:href='#editorBold' />
				</svg>
			</button>
			<button :class='{ isActive: isActive.underline() }' @click='commands.underline'>
				<svg class='miniIcon'>
					<use xlink:href='#editorUnderline' />
				</svg>						
			</button>
			<button :class='{ isActive: isActive.bullet_list() }' @click='commands.bullet_list'>
				<svg class='miniIcon'>
					<use xlink:href='#editorBulletList' />
				</svg>	
			</button>
			<button :class='{ isActive: isActive.ordered_list() }' @click='commands.ordered_list'>
				<svg class='miniIcon'>
					<use xlink:href='#editorOrderedList' />
				</svg>	
			</button>
			<button :class='{ isActive: isActive.link() }' @click='getMarkAttrs("link").href ? setLinkUrl(commands.link, null) : showLinkMenu(commands.link, getMarkAttrs("link"))'>
				<svg class='miniIcon'>
					<use xlink:href='#editorLink' />
				</svg>	
			</button>
		</div>
	</EditorMenuBubble>
	<EditorContent 
		ref='editor'
		@keyup.native='e=>$emit("keyup",e)'
		:editor='editor' 
		:class='{
			collapsed: collapseOnInactive && !started
		}'
	/>
</div>
</template>

<script>
import uniqid from 'uniqid'
//import { throttle } from 'lodash'
import { Editor, EditorContent, EditorMenuBubble, Extension, Plugin } from 'tiptap'
import { Heading, Bold, Link, ListItem, BulletList, OrderedList, Underline, History, Placeholder, HardBreak } from 'tiptap-extensions'
import { smallModalMixin } from '@/components/common/mixins/modal'
import TextInputModal from '@/components/common/modals/TextInput'

class EnterHandler extends Extension {
	get name() { return 'enter_handler' }
	get plugins() {
		return [
			new Plugin({
				props: {
					handleKeyDown: (view, event) => {
						if (event.key === 'Enter') return true
					}
				}
			})
		]
	}
}

export default {
	name: 'RichText',
	components: {
		EditorContent,
		EditorMenuBubble
	},
	mixins: [smallModalMixin],
	props: ['value', 'placeholder', 'disabled', 'collapseOnInactive', 'maxlength', 'plain'],
	directives: {
		position: {
			update: function(el, binding) {
				const { container, left, bottom } = binding.value
				const menuWidth = el.getBoundingClientRect().width
				const editorWidth = container.getBoundingClientRect().width
				const offset = left - menuWidth / 2
				let contained
				if (offset < 0) contained = 0
				else if (offset > editorWidth - menuWidth) contained = editorWidth - menuWidth
				else contained = offset
				el.style.left = contained + 'px'
				el.style.bottom = bottom + 'px'
			}
		}
	},
	data: () => ({
		inEditor: false,
		editorFocussed: false,
		started: false,
		editor: null,
		linkUrl: null,
		//linkMenuIsActive: false,
		id: '_' + uniqid(),
		errTooLong: false,
	}),
	methods: {
		initEditor() {
			const extensions = [
				new Heading(),
				new Bold(),
				new Link(),
				new ListItem(),
				new BulletList(),
				new OrderedList(),
				new Underline(),
				new History(),
				new Placeholder({
					emptyClass: 'isEmpty',
					emptyNodeText: this.placeholder,
				}),
				new HardBreak()
			]
			if (this.plain) extensions.push(new EnterHandler())
			this.editor = new Editor({
				extensions,
				editable: !this.disabled,
				content: this.value,
				onUpdate: ({ getHTML }) => {
					const v = this.plain ? getHTML().replace(/<[^>]+>/g, ' ').trim() : getHTML()
					if (this.active) this.$emit('input', v) 
					this.errTooLong = this.maxlength && v.length > this.maxlength
				},
				/*
				onUpdate: throttle(({ getHTML }) => {
					if (this.active) this.$emit('input', getHTML()) 
					this.errTooLong = this.maxlength && getHTML().length > this.maxlength
				}, 250, { leading: false, trailing: true }),
				*/
				onFocus: () => {
					this.inEditor = true
					this.editorFocussed = true
					this.started = true
					this.$emit('focus')
				},
				onBlur: () => {
					this.editorFocussed = false
					if (this.plain) {
						setTimeout(()=>{
							this.editor.destroy()
							this.initEditor()
						},0)
					}
				},
			})
		},
		onFocusIn(e) {
			if (!e.target.closest('#'+this.id)) this.inEditor = false
		},
		showLinkMenu(command, attrs) {
			this.linkUrl = attrs.href
			const fn = link => this.setLinkUrl(command, link)
			this.showSmallModal(TextInputModal, {
				title: "Add link",
				placeholder: "Link...",
				value: this.linkUrl,
				button: "Ok",
				maxlength: 100,
				fn
			})	
			//this.linkMenuIsActive = true
			//this.$nextTick(() => this.$refs.linkInput.focus())
		},
		/*
		hideLinkMenu() {
			this.linkUrl = null
			this.editor.focus()
			setTimeout(() => this.linkMenuIsActive = false, 0)
		},
		*/
		setLinkUrl(command, url) {
			const withHttp = url => !/^https?:\/\//i.test(url) ? `http://${url}` : url
			command({ href: url ? withHttp(url) : null })
		//	this.hideLinkMenu()
			this.editor.focus()
		},
		focus(select) {
			this.editor.focus()
			if (select===true) {
				const node = this.$el.querySelector('.ProseMirror')
				const selection = window.getSelection()
				const range = document.createRange()
				range.selectNodeContents(node)
				selection.removeAllRanges()
				selection.addRange(range)
			}
		}
	},
	mounted() {
		this.active = true
		document.addEventListener('focusin', this.onFocusIn)
		this.initEditor()
	},
	beforeDestroy() {
		this.active = false
		this.editor.destroy()
		document.removeEventListener('focusin', this.onFocusIn)
	}
}
</script>

<style lang='scss'>
html {
	user-select: none !important;
}

.richText { 
	position: relative;

	&.disabled { 
		.ProseMirror { background: $color-white !important; }
		.menuBubble { visibility: hidden !important; }
	}

	.errTooLong {
		position: absolute;
		top: $size-gutter + 1;
		right: $size-gutter + 1;
		z-index: 10;
	}
	
	&.focussed .ProseMirror { background: $color-focus; }

	&.tooLong .ProseMirror { background: lighten($color-alert, 45%); }

	.ProseMirror {
		@include rich;
		min-width: 0;
		outline: none;
		padding: $size-gutter * 2;
		padding-top: 11.5px;
		padding-bottom: 14.5px;
		background: $color-white;
		transition: background .2s;

		> *:only-child {
			margin-bottom: -3px;
		}
	}

	.menuBubble {
		position: absolute;
		z-index: 20;
		display: flex;
		background: $button-gradient-primary;
		
		height: $size-control-height;
		visibility: hidden;
		opacity: 0;
		overflow: hidden;
		transition: opacity 0.2s, visibility 0.2s;

		&.isActive {
			opacity: 1;
			visibility: visible;
		}

		& button {
			position: relative;
			display: flex;
			border: none;
			background: none;
			outline: none;
			align-items: center;
			padding-left: $size-gutter * 2;
			padding-right: $size-gutter * 2;
			height: 100%;
			color: $color-white;
			cursor: pointer;

			&:after {
				content: '';
				position: absolute;
				top: 25%;
				right: 0;
				width: 1px;
				height: 50%;
				background: rgba($color-white, 0.4);
			}

			&:last-child:after {
				display: none;
			}
		}

		& form {
			position: absolute;
			z-index: 10;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			background: $button-gradient-primary;

			& input[type='text'] {
				flex: 1;
				background: none !important;
				color: $color-white;

				&::placeholder {
					color: $color-white !important;
				}
			}

			& button {
				flex: 0
			}
		}
	}

	p.is-empty:first-child {

		&::before {
			content: attr(data-empty-text);
			float: left;	
			color: lighten($color-black, 35%);
			pointer-events: none;
			height: 0;
		}
	}

	* {
		user-select: text;
	}

	.collapsed {

		.ProseMirror {
			white-space: nowrap;
			text-overflow: ellipsis;
			user-select: none;
			height: 40px;
			overflow: hidden;
			min-width: 0;


			* {
				margin: 0;
				padding: 0;
				display: inline;
				white-space: nowrap;

				&:after { content: ' ' }
			}

			br { content: ' ' }

			li::before {
				display: none !important;
			}
		}
	}
}
</style>