var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"richText",class:{
		disabled: _vm.disabled,
		focussed: _vm.editorFocussed || (_vm.inEditor && !_vm.$store.state.main.mobile && _vm.$refs.editorMenuBubble && _vm.$refs.editorMenuBubble.menu.isActive),
		tooLong: _vm.errTooLong
	},attrs:{"id":_vm.id}},[(_vm.errTooLong)?_c('div',{staticClass:"errTooLong miniButton alert"},[_vm._v(_vm._s(_vm.$t('elements.labels.tooLong')))]):_vm._e(),(!_vm.plain && !_vm.$store.state.main.mobile)?_c('EditorMenuBubble',{ref:"editorMenuBubble",staticClass:"menuBubble",attrs:{"editor":_vm.editor},scopedSlots:_vm._u([{key:"default",fn:function({ commands, isActive, getMarkAttrs, menu }){return _c('div',{directives:[{name:"position",rawName:"v-position",value:({ left: menu.left, bottom: menu.bottom, container: _vm.$el }),expression:"{ left: menu.left, bottom: menu.bottom, container: $el }"}],class:{ isActive: menu.isActive && _vm.inEditor }},[_c('button',{class:{ isActive: isActive.bold() },on:{"click":commands.bold}},[_c('svg',{staticClass:"miniIcon"},[_c('use',{attrs:{"xlink:href":"#editorBold"}})])]),_c('button',{class:{ isActive: isActive.underline() },on:{"click":commands.underline}},[_c('svg',{staticClass:"miniIcon"},[_c('use',{attrs:{"xlink:href":"#editorUnderline"}})])]),_c('button',{class:{ isActive: isActive.bullet_list() },on:{"click":commands.bullet_list}},[_c('svg',{staticClass:"miniIcon"},[_c('use',{attrs:{"xlink:href":"#editorBulletList"}})])]),_c('button',{class:{ isActive: isActive.ordered_list() },on:{"click":commands.ordered_list}},[_c('svg',{staticClass:"miniIcon"},[_c('use',{attrs:{"xlink:href":"#editorOrderedList"}})])]),_c('button',{class:{ isActive: isActive.link() },on:{"click":function($event){getMarkAttrs("link").href ? _vm.setLinkUrl(commands.link, null) : _vm.showLinkMenu(commands.link, getMarkAttrs("link"))}}},[_c('svg',{staticClass:"miniIcon"},[_c('use',{attrs:{"xlink:href":"#editorLink"}})])])])}}],null,false,1719562798)}):_vm._e(),_c('EditorContent',{ref:"editor",class:{
			collapsed: _vm.collapseOnInactive && !_vm.started
		},attrs:{"editor":_vm.editor},nativeOn:{"keyup":function($event){return (e=>_vm.$emit("keyup",e)).apply(null, arguments)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }